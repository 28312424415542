@tailwind base;
@tailwind components;
@tailwind utilities;
.ql-toolbar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ql-container {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.inner-html > ol,ul{
  list-style: inside;
}
.inner-html > ol{
  list-style-type: decimal;
}
.inner-html > ul{
  list-style-type: disc;
}
.breadcrumb-last:last-child::after{
  content: "";
}


@font-face {
  font-family: 'PlusJakartaSans';
  font-style: normal;
  font-display: swap;
  src: url('/assets/font/PlusJakarta/PlusJakartaSans-Regular.woff2') format('woff2');
}

@font-face {
  font-family: 'PlusJakartaSansBold';
  font-style: normal;
  font-display: swap;
  src: url('/assets/font/PlusJakarta/PlusJakartaSans-ExtraBold.woff2') format('woff2');
}

@layer base {
  html {
    @apply font-PlusJakartaSans;
  }
}
